// constant variables

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "development";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ASSET_BASE_URL = process.env.REACT_APP_ASSET_BASE_URL;

export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

export const LOGIN_TIMER = 30;

// Social media links
export const FACEBOOK_URL = "https://www.facebook.com/docfliq/";
export const LINKEDIN_URL = "https://www.linkedin.com/company/docfliq/";
export const INSTAGRAM_URL = "https://www.instagram.com/docfliq";
export const TWITTER_URL = "https://x.com/docfliq";

// Default Messages
export const ADD_SUCCESS_MSG = "Data added successfully";
export const UPDATE_SUCCESS_MSG = "Data updated successfully";
export const DELETE_SUCCESS_MSG = "Data deleted successfully";
export const REQUEST_PROGRESS_MSG = "Request in progress";
export const NEWSLETTER_SUBSCRIPTION_SUCCESS_MSG =
  "Thank you for subscribing our newsletter";

export const DATE_FORMAT = "YYYY-MM-DD";
export const RESEND_OTP_TIMER = 15;
export const INDIAN_COUNTRY_CODE = "IN";
export const INDIAN_DIALING_CODE = "91";
export const DELETE_CONFIRMATION_TEXT = "delete";
export const MIN_VALUE_LENGTH = 3;
export const MED_VALUE_LENGTH = 500;
export const MAX_VALUE_LENGTH = 1000;

// Repositories for s3 bucket to upload files
export const REPO_FOR_CHANNEL = "channel";
export const REPO_FOR_VIDEO = "video";
export const REPO_FOR_USER = "users";
export const REPO_FOR_BANNER = "banners/high";
export const REPO_FOR_AFFILIATION = "affiliations/high";
export const REPO_FOR_PROMOTION_VIDEO = "promotion_video";

export const AUTH_TOKEN_KEY = "token";
export const USER_DETAILS_KEY = "user";

export const ABOUT_US_KEYS = [
  {
    label: "Docfliq email",
    value: "Docfliq email",
  },
  {
    label: "Docfliq sms",
    value: "Docfliq sms",
  },
  {
    label: "Recommended by colleague",
    value: "Recommended by colleague",
  },
  {
    label: "Google",
    value: "Google",
  },
  {
    label: "Whatsapp invite",
    value: "Whatsapp invite",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
  {
    label: "Instagram",
    value: "Instagram",
  },
  {
    label: "Twitter",
    value: "Twitter",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const SALUTATIONS = [
  {
    label: "Dr.",
    value: "Dr.",
  },
  {
    label: "Prof.",
    value: "Prof.",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Miss.",
    value: "Miss.",
  },
];

export const MSD_STATE_COUNCILS = [
  { value: 0, label: "Select State Council" },
  { value: 1, label: "Andhra Pradesh Medical Council" },
  { value: 2, label: "Arunachal Pradesh Medical Council" },
  { value: 3, label: "Assam Medical Council" },
  { value: 4, label: "Bihar Medical Council" },
  { value: 28, label: "Bhopal Medical Council" },
  { value: 29, label: "Bombay Medical Council" },
  { value: 30, label: "Chandigarh Medical Council" },
  { value: 5, label: "Chhattisgarh Medical Council" },
  { value: 6, label: "Delhi Medical Council" },
  { value: 7, label: "Goa Medical Council" },
  { value: 8, label: "Gujarat Medical Council" },
  { value: 9, label: "Haryana Medical Council" },
  { value: 10, label: "Himachal Medical Council" },
  { value: 45, label: "Hyderabad Medical Council" },
  { value: 11, label: "Jammu & Kashmir Medical Council" },
  { value: 12, label: "Jharkhand Medical Council" },
  { value: 13, label: "Karnataka Medical Council" },
  { value: 15, label: "Madhya Pradesh Medical Council" },
  { value: 36, label: "Madras Medical Council" },
  { value: 35, label: "Mahakoshal Medical Council" },
  { value: 26, label: "Manipur Medical Council" },
  { value: 16, label: "Maharashtra Medical Council" },
  { value: 46, label: "Medical Council of India" },
  { value: 47, label: "Medical Council of Tanganyika" },
  { value: 42, label: "Mizoram Medical Council" },
  { value: 37, label: "Mysore Medical Council" },
  { value: 41, label: "Nagaland Medical Council" },
  { value: 17, label: "Orissa Council of Medical Registration" },
  { value: 38, label: "Pondicherry Medical Council" },
  { value: 18, label: "Punjab Medical Council" },
  { value: 19, label: "Rajasthan Medical Council" },
  { value: 20, label: "Sikkim Medical Council" },
  { value: 21, label: "Tamil Nadu Medical Council" },
  { value: 43, label: "Telnagan State Medical Council" },
  { value: 50, label: "Travancore Cochin Medical Council" },
  { value: 22, label: "Tripura State Medical Council" },
  { value: 23, label: "Uttar Pradesh Medical Council" },
  { value: 40, label: "Vidharba Medical Council" },
  { value: 24, label: "Uttarakhand Medical Council" },
  { value: 25, label: "West Bengal Medical Council" },
];


// Define the priorities
export const PRIORITY_SPECIALTIES = ['dietician / nutritionist', 'gastroenterology'];


export const VIDEO_PREFIX_PRIMARY_BASE_URL = process.env.REACT_APP_VIDEO_PREFIX_PRIMARY_BASE_URL;
export const VIDEO_PREFIX_BACKUP_BASE_URL = process.env.REACT_APP_VIDEO_PREFIX_BACKUP_BASE_URL;


export const DEBATE_2024_CHANNEL =
  process.env.REACT_APP_DEBATE_2024_CHANNEL;
export const DEBATE_2024_CHANNEL_AGENDA =
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate_2024_agenda_final.pdf";
export const DEBATE_2024_CHANNEL_AGENDA_IMAGES_ARRAY = [
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-1.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-2.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-3.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-4.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-5.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-6.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-7.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-8.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-9.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-10.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-11.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/debate-2024-agenda-12.jpg",
];

export const CARING_FOR_CAUSE_CHANNEL = process.env.REACT_APP_CFAC_CHANNEL;
export const MNT_MASTER_CLASS_CHANNEL = process.env.REACT_APP_MNT_MASTER_CLASS_CHANNEL;

export const HER2_ICP_CHANNEL = process.env.REACT_APP_HER_2_ICP_CHANNEL;
export const HER2_ICP_CHANNEL_FEEDBACK_URL =
  "https://docs.google.com/forms/d/1M1d8PBS7kGQZJdQumvFLMBXJSUGbI8NlkyM7mLvTj-Q/edit";
export const HER2_ICP_CHANNEL_CERTIFICATE_MESSAGE =
  "Participation certificate will be emailed to the participants who attend both days of the symposium and complete the feedback form. For any queries, please contact ";
export const HER2_ICP_CHANNEL_SUPPORT_MAIL = "support@docfliq.com";
export const HER2_ICP_CHANNEL_AGENDA =
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/roche-agenda.pdf";
export const HER2_ICP_CHANNEL_AGENDA_IMAGES_ARRAY = [
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/roche-agenda-1.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/roche-agenda-2.jpg",
  "https://s3-docfliq-v2.s3.ap-south-1.amazonaws.com/agenda/roche-agenda-3.jpg",
];

export const SYNERGY_CHANNEL = process.env.REACT_APP_SYNERGY_CHANNEL;

export const IBD_AWARE_CHANNEL = process.env.REACT_APP_IBD_AWARE_CHANNEL;
export const IBD_AWARE_CHANNEL_PRE_ASSESSMENT =
  process.env.REACT_APP_IBD_AWARE_CHANNEL_PRE_ASSESSMENT;
export const IBD_AWARE_SUMMARY_BOOKLET =
  process.env.REACT_APP_IBD_AWARE_SUMMARY_BOOKLET;

export const IBD_BRIDGE_CHANNEL = process.env.REACT_APP_IBD_BRIDGE_CHANNEL;
export const IBD_BRIDGE_CHANNEL_PRE_ASSESSMENT =
  process.env.REACT_APP_IBD_BRIDGE_CHANNEL_PRE_ASSESSMENT;

  export const LEAP_SPAF_CHANNEL = process.env.REACT_APP_LEAP_SPAF_CHANNEL;
export const LEAP_SPAF_CHANNEL_PRE_ASSESSMENT =
  process.env.REACT_APP_LEAP_SPAF_CHANNEL_PRE_ASSESSMENT;

export const ABBOTT_NUTRITION_PRO_CHANNEL =
  process.env.REACT_APP_ABBOTT_NUTRITION_PRO_CHANNEL;

export const NOT_FOUND = 404;

export const ANONYMOUS_QUESTION_MAX_WORD_LIMIT = 100;

export const MNT_MOODLE_CHANNEL = "6638bfb247239bacc5ab80df";

export const I18_NEXT_LANGUAGE_KEY = 'i18nextLng';