export const USERS_API = "/app/user";
export const CHANNELS_API = "/app/channel";
export const VIDEOS_API = "/app/video";
export const EXPERTS_API = "/app/expert";
export const BANNERS_API = "/app/banners";
export const AFFILIATIONS_API = "/app/affiliations";
export const NEWSLETTER_API = "/app/newsletter-subscribe";
export const COMMENTS_API = "/app/comments";
export const SPECIALTIES_API = "/app/specialities";
export const SPECIALTIES_API_V2 = "/app/specialties";
export const GEOGRAPHY_API = "/app/geography";
export const ASSESSMENTS_API = "/app/assessments";
export const PROMOTION_VIDEOS_API = "/app/promotion-videos";
export const LIVE_QUESTIONS_API = "/app/question";
export const CERTIFICATES_API = "/app/certificates";
export const CONTACT_US_API = "/app/contact-us";


export const AUTH_API = "/app/auth";
export const AUTH_REQUEST_OTP_API = "/app/auth/custom-login-redis";
export const AUTH_SUBMIT_OTP_API = "/app/auth/custom-verify-redis";
export const CHECK_NEW_USER_PROFILE_COMPLETION_API = "/app/auth/check-new-user-profile-completion";

export const ASSETS_API = "/app/assets";

export const SUBSCRIPTION_API = "/app/subscription-payment";

export const SEARCH_API = "/app/search";

export const POLLS_API = "/app/polls";

export const NMC_VERIFICATION_URL =
  "https://www.nmc.org.in/MCIRest/open/getPaginatedData";

export const COUNTRY_DETECTION_API = "https://api.country.is/";

export const ANONYMOUS_QUESTIONS_API = "/app/video/anonymous-questions";